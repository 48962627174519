import { IClaim } from 'components/inputsArea/types';
import { useAddresscloudApi } from 'context/auth-context';
import { IBibaApiResult } from 'types';

const bibaApiDomain = process.env.REACT_APP_ADDRESSCLOUD_API_DOMAIN;

export const unnestOverridesInBibaResult = (
  bibaResult: IBibaApiResult,
): IBibaApiResult => {
  const result: IBibaApiResult = { ...bibaResult, ...bibaResult.overrides };
  delete result.overrides;
  return result;
};

const useBiba = () => {
  const { get } = useAddresscloudApi(bibaApiDomain);

  const lookupByUprn = async (
    uprn: string,
    minimumFloorLevel: number | null,
    hasBasement: boolean | null,
    buildingsTsi?: number,
    contentsTsi?: number,
    businessInterruptionTsi?: number,
    concreteFloor?: boolean,
    previousClaims?: Array<IClaim>,
    bibaClass?: string | null,
    buildingBibaClass?: string | null,
  ) => get(`biba/v1/byUPRN/${uprn}`, {
    tsi_buildings: buildingsTsi,
    tsi_contents: contentsTsi,
    tsi_bi: businessInterruptionTsi,
    concrete_floor: concreteFloor,
    previous_claims: previousClaims?.map((previousClaim) => `${previousClaim.year}|${previousClaim.claimValue}`),
    floor_level: minimumFloorLevel?.toString() ?? undefined,
    basement: hasBasement?.toString() ?? undefined,
    biba_class: bibaClass || undefined,
    building_biba_class: buildingBibaClass || undefined,
  });

  return { lookupByUprn };
};

export default useBiba;

import { createUseStyles } from 'react-jss';
import { boxShadow, colorPalette } from 'utils/variables';

const useStyles = createUseStyles({
  dropdownContainer: {
    position: 'absolute' /* absolute to the navbar container */,
    borderRadius: '4px',
    width: '15rem',
    right: '1.5rem',
    top: '4rem',
    overflow: 'hidden',
    zIndex: 5,
    background: 'white',
    boxShadow,
  },
  dropdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    cursor: 'pointer',
    transition: 'background 0.2s ease-in',
    '&:hover': {
      background: colorPalette.yellow,
    },
  },
});

export default useStyles;

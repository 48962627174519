import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { JbaReturnPeriodTableHeaders } from 'utils/variables';
import StyledTableCell from './StyledTableCell';
import { IJbaFloodTableProps } from './types';

const JbaReturnPeriod = ({ results }: IJbaFloodTableProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    river_rp, surface_rp, coastal_rp,
  } = results;
  return (
    <TableContainer sx={{ margin: '1rem 0' }}>
      <Table>
        <TableHead>
          <TableRow>
            {JbaReturnPeriodTableHeaders.map((header) => (
              <StyledTableCell cellContent={header} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell cellContent={river_rp != null ? `1 in ${river_rp}` : 'N/A'} />
            <StyledTableCell cellContent={surface_rp != null ? `1 in ${surface_rp}` : 'N/A'} />
            <StyledTableCell cellContent={coastal_rp != null ? `1 in ${coastal_rp}` : 'N/A'} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default JbaReturnPeriod;

/* eslint-disable no-console */
import {
  useReducer, useState, useRef, useCallback,
} from 'react';
import { AuthProtectedComponent } from 'context/auth-context';
import Map from 'components/map/Map';
import { MapRef } from 'react-map-gl';
import Navbar from 'components/navbar/Navbar';
import InputsArea from 'components/inputsArea/InputsArea';
import { IBibaApiResult, IMatchResult } from 'types';
import Footer from 'components/footer/Footer';
import Loading from 'views/Loading';
import AuthError from 'views/AuthError';
import Results from 'components/results/Results';
import { IPropertyInfo } from 'components/inputsArea/types';
import { useAuth0 } from '@auth0/auth0-react';
import { defaultPropertyInfo } from 'utils/variables';
import useStyles from './App.style';
import generatePdf from './utils/generatePdf';

const previousClaimsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'update': return state.map((claim: any, index: any) => {
      if (index === action.claimNumber) {
        return { ...claim, ...action.claim };
      }
      return claim;
    });
    case 'add': {
      return [...state, { year: null, claimValue: null }];
    }
    case 'remove':
      return state.slice(0, -1);
    case 'removeAll':
      return [];
    default:
      return state;
  }
};

const App = () => {
  const { user } = useAuth0();
  const [propertyInfo, setPropertyInfo] = useState<IPropertyInfo>(
    () => ({ ...defaultPropertyInfo }),
  );
  const [previousClaims, previousClaimsDispatch] = useReducer(previousClaimsReducer, []);
  const [matchResult, setMatchResult] = useState<IMatchResult | null>(null);
  const [bibaApiResult, setBibaApiResult] = useState<IBibaApiResult | null>(null);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const mapRef = useRef<MapRef | null>(null);

  const { app, mainContainer } = useStyles();

  const handleMatch = (result: IMatchResult) => {
    setMatchResult(result);
  };

  const refreshApp = useCallback(() => {
    setMatchResult(null);
    previousClaimsDispatch({ type: 'removeAll' });
    setBibaApiResult(null);
    setIsFormSubmitted(false);
    setPropertyInfo({ ...defaultPropertyInfo });
  }, []);

  const trackPDFGeneration = () => {
    try {
      // @ts-ignore
      fathom.trackEvent('Biba PDF');
    } catch (e) {
      console.error(e);
    }
  };

  const handlePdf = useCallback(async () => {
    setIsPdfGenerating(true);
    try {
      await generatePdf(
        mapRef?.current,
        matchResult,
        bibaApiResult,
        user,
        propertyInfo,
      );
      trackPDFGeneration();
    } catch (e) {
      console.error(e);
    }
    setIsPdfGenerating(false);
  }, [matchResult, bibaApiResult, user, propertyInfo]);

  return (
    <AuthProtectedComponent
      renderLoading={() => <Loading />}
      renderError={() => <AuthError />}
    >
      <div className={app}>
        <Navbar
          handlePdf={handlePdf}
          refreshApp={refreshApp}
          isPdfGenerationDisabled={!(matchResult !== null && bibaApiResult !== null)}
        />
        <div className={mainContainer}>
          <InputsArea
            handleMatch={handleMatch}
            matchResult={matchResult}
            isFormSubmitted={isFormSubmitted}
            setIsFormSubmitted={setIsFormSubmitted}
            propertyInfo={propertyInfo}
            setPropertyInfo={setPropertyInfo}
            previousClaims={previousClaims}
            previousClaimsDispatch={previousClaimsDispatch}
            setBibaApiResult={setBibaApiResult}
          />
          {isFormSubmitted ? (
            <>
              <Results
                propertyInfo={propertyInfo}
                setPropertyInfo={setPropertyInfo}
                bibaApiResult={bibaApiResult}
                matchResult={matchResult}
                previousClaims={previousClaims}
                setBibaApiResult={setBibaApiResult}
              />
              <Map
                matchCoordinates={matchResult?.geometry.coordinates}
                toid={bibaApiResult?.toid}
                mapRef={mapRef}
                isPdfGenerating={isPdfGenerating}
              />
            </>
          ) : null}
        </div>
        <Footer />
      </div>
    </AuthProtectedComponent>
  );
};

export default App;

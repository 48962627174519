import { Input, MenuItem, Select } from '@mui/material';
import useStyles from './Results.style';
import { IPreFilledResultsProps } from './types';

const minimumFloorLevelNumberToString = (minimumFloorLevel: number | null): string => {
  if (minimumFloorLevel === null) return '';
  if (minimumFloorLevel <= -1) return '-1';
  if (minimumFloorLevel >= 2) return '2+';

  return minimumFloorLevel.toString();
};

const PreFilledResults = ({
  results, propertyInfo, handleBibaClassChange, handleBuildingBibaClassChange,
  handleHasBasementChange, handleMinimumFloorLevelChange,
}: IPreFilledResultsProps) => {
  const {
    preFilledResults,
    preFilledResultsStyle,
  } = useStyles();

  const {
    biba_class: bibaClass,
    building_biba_class: buildingBibaClass,
  } = results;

  return (
    <div className={preFilledResults}>
      <div className={preFilledResultsStyle}>
        <h2>BIBA Class</h2>
        <Select
          value={bibaClass ?? ''}
          onChange={handleBibaClassChange}
          displayEmpty
          input={<Input sx={{ fontSize: '0.9rem' }} />}
        >
          {
            bibaClass === undefined ? (
              <MenuItem value="" style={{ pointerEvents: 'none' }}>Not Available</MenuItem>
            ) : null
          }
          <MenuItem value="General">General</MenuItem>
          <MenuItem value="HiTech">HiTech</MenuItem>
          <MenuItem value="Hotel">Hotel</MenuItem>
          <MenuItem value="Office">Office</MenuItem>
          <MenuItem value="Retail">Retail</MenuItem>
          <MenuItem value="Warehouse">Warehouse</MenuItem>
          <MenuItem value="Residential">Residential</MenuItem>
        </Select>
      </div>
      <div className={preFilledResultsStyle}>
        <h2>Building BIBA Class</h2>
        <Select
          value={buildingBibaClass ?? ''}
          onChange={handleBuildingBibaClassChange}
          displayEmpty
          input={<Input sx={{ fontSize: '0.9rem' }} />}
        >
          {
            buildingBibaClass === undefined ? (
              <MenuItem value="" style={{ pointerEvents: 'none' }}>Not Available</MenuItem>
            ) : null
          }
          <MenuItem value="General">General</MenuItem>
          <MenuItem value="HiTech">HiTech</MenuItem>
          <MenuItem value="Hotel">Hotel</MenuItem>
          <MenuItem value="Office">Office</MenuItem>
          <MenuItem value="Retail">Retail</MenuItem>
          <MenuItem value="Warehouse">Warehouse</MenuItem>
          <MenuItem value="Residential">Residential</MenuItem>
        </Select>
      </div>
      <div className={preFilledResultsStyle}>
        <h2>Minimum Floor Level</h2>
        <Select
          value={minimumFloorLevelNumberToString(propertyInfo.minimumFloorLevel)}
          onChange={handleMinimumFloorLevelChange}
          displayEmpty
          input={<Input sx={{ fontSize: '0.9rem' }} />}
        >
          <MenuItem value="">Not Available</MenuItem>
          <MenuItem value="-1">-1</MenuItem>
          <MenuItem value="0">0</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2+">2+</MenuItem>
        </Select>
      </div>
      <div className={preFilledResultsStyle}>
        <h2>Basement</h2>
        <Select
          value={propertyInfo.hasBasement?.toString() ?? ''}
          onChange={handleHasBasementChange}
          displayEmpty
          input={<Input sx={{ fontSize: '0.9rem' }} />}
        >
          <MenuItem value="">Not Available</MenuItem>
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default PreFilledResults;

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { parse, stringify } from 'qs';

const axiosInstance = axios.create({ timeout: 5000 });

axiosRetry(axiosInstance, {
  retries: 3,
  retryCondition: (error: any) => (
    !error.response
      || axiosRetry.isNetworkOrIdempotentRequestError(error)
      || error.response.status === 429
      || error.response.status === 504
  ),
});

const get = async (
  endpoint: string,
  params?: object,
  headers?: object,
  baseURL?: string,
) => axiosInstance.get(endpoint, {
  params,
  paramsSerializer: {
    // @ts-ignore
    encode: parse,
    // @ts-ignore
    serialize: stringify,
    arrayFormat: 'repeat',
  },
  headers,
  baseURL,
});

const post = async (
  endpoint: string,
  data?: object,
  headers?: object,
  baseURL?: string,
) => axiosInstance.post(endpoint, {
  data,
  headers,
  baseURL,
});

export { get, post };

import { ReactComponent as AddresscloudLogo } from 'assets/AddresscloudLogo-Colors.svg';
import MiCommercialLogo from 'assets/MiCommercialRisk.png';
import useStyles from './Footer.style';

const Footer: React.FC = () => {
  const {
    footerContainer,
    footerInsideContainer,
    addresscloudLogoContainer,
    miCommercialLogo,
  } = useStyles();
  return (

    <footer className={footerContainer}>
      <div className={footerInsideContainer}>
        <div className={miCommercialLogo}>
          <p>Managed by</p>
          <img src={MiCommercialLogo} alt="MiCommercialRisk Logo" />
        </div>
        <div className={addresscloudLogoContainer}>
          <p>Powered by</p>
          <AddresscloudLogo />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

/* eslint-disable react/no-array-index-key */
import { IReferralProps } from './types';
import useStyles from './Results.style';

const Referral = ({ referral }: IReferralProps) => {
  const { warnings, referrals } = referral;
  const warningsStatus = warnings.status;
  const warningsMessages = warnings.messages;
  const warningsMessagesLength = warningsMessages.length;
  const referralsStatus = referrals.status;
  const referralsMessages = referrals.messages;
  const referralsMessagesLength = referralsMessages.length;

  const { warningsContainer, referralsContainer } = useStyles();

  return (
    <div>
      {warningsStatus !== 'OK'
        ? (
          <div className={warningsContainer}>
            <h2>Warnings</h2>
            <p>
              Status:
              {' '}
              <span>{warningsStatus}</span>
            </p>
            <p>
              Messages:
              {warningsMessages.map((message, index) => (
                <span key={index}>
                  {' '}
                  {message}
                  {warningsMessagesLength - 1 !== index ? '; ' : '.'}
                </span>
              ))}
            </p>
          </div>
        ) : null}
      {referralsStatus !== 'OK'
        ? (
          <div className={referralsContainer}>
            <h2>Referrals</h2>
            <p>
              Status:
              <span>
                {' '}
                {referralsStatus}
              </span>
            </p>
            <p>
              Messages:
              {referralsMessages.map((message, index) => (
                <span key={index}>
                  {' '}
                  {message}
                  {referralsMessagesLength - 1 !== index ? '; ' : '.'}
                </span>
              ))}
            </p>
          </div>
        ) : null }
    </div>
  );
};

export default Referral;

export default [
  {
    id: 'jbafgb-floodability_def',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-floodability_def',
    minzoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': [
        'match',
        ['to-number', ['get', 'gridcode']],
        1,
        '#4caf50',
        2,
        '#ffc107',
        3,
        '#f44335',
        4,
        '#767676',
        5,
        '#000000',
        '#000000',
      ],
      'fill-opacity': 0.5,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    },
  },
  {
    id: 'jbafgb-floodability_ud',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-floodability_ud',
    minzoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': [
        'match',
        ['to-number', ['get', 'gridcode']],
        1,
        '#4caf50',
        2,
        '#ffc107',
        3,
        '#f44335',
        4,
        '#767676',
        5,
        '#000000',
        '#000000',
      ],
      'fill-opacity': 0.5,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    },
  },
  {
    id: 'jbafgb-coastal_ud',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-coastal_ud',
    minZoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': [
        'match',
        ['to-number', ['get', 'DN']],
        75,
        '#006d2c',
        100,
        '#41ab5d',
        200,
        '#74c476',
        1000,
        '#b2df8a',
        '#000000',
      ],
      'fill-opacity': 0.5,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    },
  },
  {
    id: 'jbafgb-coastal_def',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-coastal_def',
    minZoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': [
        'match',
        ['to-number', ['get', 'DN']],
        75,
        '#006d2c',
        100,
        '#41ab5d',
        200,
        '#74c476',
        1000,
        '#b2df8a',
        '#000000',
      ],
      'fill-opacity': 0.5,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    },
  },
  {
    id: 'jbafgb-river_ud',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-river_ud',
    minZoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': [
        'match',
        ['to-number', ['get', 'DN']],
        20,
        '#12376a',
        75,
        '#2171b5',
        100,
        '#4292c6',
        200,
        '#6baed6',
        1000,
        '#a6cee3',
        '#000000',
      ],
      'fill-opacity': 0.5,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    },
  },
  {
    id: 'jbafgb-surface_ud',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-surface_ud',
    minZoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': [
        'match',
        ['to-number', ['get', 'DN']],
        75,
        '#63439c',
        200,
        '#8c88c0',
        1000,
        '#babbdb',
        '#000000',
      ],
      'fill-opacity': 0.5,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    },
  },
  {
    id: 'jbafgb-dras',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-dras',
    minZoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': '#12376a',
      'fill-outline-color': '#12376a',
      'fill-opacity': 0.3,
    },
  },
  {
    id: 'jbafgb-dras_labels',
    type: 'symbol',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-dras_labels',
    minzoom: 14,
    filter: ['==', '$type', 'Point'],
    layout: {
      'text-field': ['to-string', ['get', 'jba_sop']],
      'text-font': ['Source Sans Pro Regular'],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        13,
        22,
        22,
      ],
    },
    paint: {
      'text-color': '#f44336',
    },
  },
  {
    id: 'jbafgb-dswa',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-dswa',
    minZoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': '#12376a',
      'fill-outline-color': '#12376a',
      'fill-opacity': 0.3,
    },
  },
  {
    id: 'jbafgb-dswa_labels',
    type: 'symbol',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-dswa_labels',
    minzoom: 14,
    filter: ['==', '$type', 'Point'],
    layout: {
      'text-field': ['to-string', ['get', 'jba_sop']],
      'text-font': ['Source Sans Pro Regular'],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        13,
        22,
        22,
      ],
    },
    paint: {
      'text-color': '#f44336',
    },
  },
  {
    id: 'jbafgb-idbs',
    type: 'fill',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-idbs',
    minZoom: 14,
    filter: ['==', '$type', 'Polygon'],
    paint: {
      'fill-color': '#12376a',
      'fill-outline-color': '#12376a',
      'fill-opacity': 0.3,
    },
  },
  {
    id: 'jbafgb-idbs_labels',
    type: 'symbol',
    source: 'addresscloud.jbafgb',
    'source-layer': 'jbafgb-idbs_labels',
    minzoom: 14,
    filter: ['==', '$type', 'Point'],
    layout: {
      'text-field': ['to-string', ['get', 'idb_name']],
      'text-font': ['Source Sans Pro Regular'],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        13,
        22,
        22,
      ],
    },
    paint: {
      'text-color': '#f44336',
    },
  },
];

import { ChangeEvent } from 'react';
import useBiba, { unnestOverridesInBibaResult } from 'hooks/use-biba';
import { IBibaApiResponse } from 'types';
import useStyles from './Results.style';
import { IResults } from './types';
import EGLResults from './EglResults';
import PreFilledResults from './PreFilledResults';
import AdditionalDetails from './AdditionalResults';

const Results = ({
  propertyInfo, setPropertyInfo, bibaApiResult, matchResult, previousClaims, setBibaApiResult,
}: IResults) => {
  const {
    resultsContainer,
  } = useStyles();

  const { lookupByUprn } = useBiba();
  if (bibaApiResult === null) return null;
  const { buildingsTsi, contentsTsi, businessInterruptionTsi } = propertyInfo;

  const refreshBibaData = async (
    uprn: number | null | undefined,
    concreteFloor: any,
    minimumFloorLevel: any,
    hasBasement: any,
    buildingsTsi_: any,
    contentsTsi_: any,
    businessInterruptionTsi_: any,
    bibaClass: string | null,
    buildingBibaClass: string | null,
  ) => {
    if (!uprn) return;
    const response = await lookupByUprn(
      uprn.toString(),
      minimumFloorLevel,
      hasBasement,
      Number(buildingsTsi_),
      Number(contentsTsi_),
      Number(businessInterruptionTsi_),
      concreteFloor,
      previousClaims,
      bibaClass,
      buildingBibaClass,
    );
    if (response.data.intel_status === 'OK') {
      const bibaResponseResults: IBibaApiResponse = response.data.results;
      const newBibaApiResult = unnestOverridesInBibaResult(
        bibaResponseResults.bibafl.bibafl.results[0],
      );
      setBibaApiResult(newBibaApiResult);
    }
  };

  const handleBibaClassChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    let newBibaClass: string | null = event.target?.value;
    if (newBibaClass === '') { newBibaClass = null; }
    setPropertyInfo({
      ...propertyInfo,
      bibaClass: newBibaClass,
    });
    refreshBibaData(
      matchResult?.properties.uprn,
      propertyInfo.concreteFloor,
      propertyInfo.minimumFloorLevel,
      propertyInfo.hasBasement,
      buildingsTsi,
      contentsTsi,
      businessInterruptionTsi,
      newBibaClass,
      propertyInfo.buildingBibaClass,
    );
  };

  const handleBuildingBibaClassChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    let newBuildingBibaClass: string | null = event.target?.value;
    if (newBuildingBibaClass === '') { newBuildingBibaClass = null; }
    setPropertyInfo({
      ...propertyInfo,
      buildingBibaClass: newBuildingBibaClass,
    });
    refreshBibaData(
      matchResult?.properties.uprn,
      propertyInfo.concreteFloor,
      propertyInfo.minimumFloorLevel,
      propertyInfo.hasBasement,
      buildingsTsi,
      contentsTsi,
      businessInterruptionTsi,
      propertyInfo.bibaClass,
      newBuildingBibaClass,
    );
  };

  const minimumFloorLevelStringToNumber = (minimumFloodLevelString: string): number | null => {
    if (minimumFloodLevelString === '') return null;
    const floorLevelDict: { [key: string]: number } = {
      '-1': -1,
      0: 0,
      1: 1,
      '2+': 2,
    };
    return floorLevelDict[minimumFloodLevelString];
  };

  const handleMinimumFloorLevelChange = async (event: any) => {
    const newMinimumFloorLevel = minimumFloorLevelStringToNumber(event.target?.value);
    setPropertyInfo(
      {
        ...propertyInfo,
        minimumFloorLevel: newMinimumFloorLevel,
      },
    );
    refreshBibaData(
      matchResult?.properties.uprn,
      propertyInfo.concreteFloor,
      newMinimumFloorLevel,
      propertyInfo.hasBasement,
      buildingsTsi,
      contentsTsi,
      businessInterruptionTsi,
      propertyInfo.bibaClass,
      propertyInfo.buildingBibaClass,
    );
  };

  const hasBasementStringToBoolean = (hasBasementString: string): boolean | null => {
    switch (hasBasementString) {
      case 'true':
        return true;
      case 'false':
        return false;
      case '':
        return null;
      default:
        return null;
    }
  };

  const handleHasBasementChange = async (event: any) => {
    const newHasBasement = hasBasementStringToBoolean(event.target?.value);
    setPropertyInfo(
      {
        ...propertyInfo,
        hasBasement: newHasBasement,
      },
    );
    refreshBibaData(
      matchResult?.properties.uprn,
      propertyInfo.concreteFloor,
      propertyInfo.minimumFloorLevel,
      newHasBasement,
      buildingsTsi,
      contentsTsi,
      businessInterruptionTsi,
      propertyInfo.bibaClass,
      propertyInfo.buildingBibaClass,
    );
  };

  return (
    <section className={resultsContainer}>
      <EGLResults propertyInfo={propertyInfo} results={bibaApiResult} />
      <PreFilledResults
        results={bibaApiResult}
        propertyInfo={propertyInfo}
        handleBibaClassChange={handleBibaClassChange}
        handleBuildingBibaClassChange={handleBuildingBibaClassChange}
        handleMinimumFloorLevelChange={handleMinimumFloorLevelChange}
        handleHasBasementChange={handleHasBasementChange}
      />
      <AdditionalDetails results={bibaApiResult} />
    </section>
  );
};

export default Results;

import { createUseStyles } from 'react-jss';
import { boxShadow, colorPalette } from 'utils/variables';

const useStyles = createUseStyles({
  matchAddressResultContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '5rem',
    width: '65%',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow,
    padding: '1rem',
  },
  matchAddressResultHeader: {
    width: '95%',
    fontSize: '1.5rem',
    lineHeight: '2.5rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  matchAddressResult: {
    marginTop: '1rem',
    fontWeight: 400,
    backgroundImage: `linear-gradient(90deg, ${colorPalette.darkBlue} 82%, ${colorPalette.yellow} 100%)`,
    color: colorPalette.yellow,
    padding: '0.5rem',
  },
  inputsAreaContainer: (openForm: boolean) => ({
    width: '65%',
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow,
    marginTop: openForm ? '1rem' : null,
  }),
  inputsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
  },
  inputs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '0.5rem',
  },
  concreteFloorsContainer: {
    width: '48%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headers: {
    margin: '2.5rem 0 1rem 0',
    color: colorPalette.yellow,
    fontSize: '1.1rem',
    fontWeight: 400,
    backgroundImage: `linear-gradient(90deg, ${colorPalette.darkBlue} 82%, ${colorPalette.yellow} 100%)`,
    padding: '0.7rem',
  },
  sliderContainer: {
    width: '48%',
  },
  assessmentButtonContainer: {
    margin: '2rem auto 0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
  intelErrorMessage: {
    margin: '1rem',
  },
});

export default useStyles;

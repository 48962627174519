export default {
  'jba-river': {
    name: 'JBA River',
    layers: ['jbafgb-river_ud'],
    minzoom: 14,
  },
  'jba-surface': {
    name: 'JBA Surface',
    layers: ['jbafgb-surface_ud'],
    minzoom: 14,
  },
  'jba-coastal-def': {
    name: 'JBA Coastal (Defended)',
    layers: ['jbafgb-coastal_def'],
    minzoom: 14,
  },
  'jba-coastal-ud': {
    name: 'JBA Coastal (Undefended)',
    layers: ['jbafgb-coastal_ud'],
    minzoom: 14,
  },
  'jba-flood-defences': {
    name: 'JBA Flood Defences',
    layers: ['jbafgb-dswa', 'jbafgb-dswa_labels', 'jbafgb-dras', 'jbafgb-dras_labels', 'jbafgb-idbs', 'jbafgb-idbs_labels'],
    minzoom: 14,
  },
};

import {
  useRef, Dispatch, SetStateAction, useLayoutEffect,
} from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { colorPalette } from 'utils/variables';
import useStyles from './UserDropdownMenu.style';

type UserDropdownMenuProps = {
  logout: (returnTo: {}) => void;
  setIsUserDropdownOpen: Dispatch<SetStateAction<boolean>>;
};

const UserDropdownMenu = ({
  logout,
  setIsUserDropdownOpen,
}: UserDropdownMenuProps) => {
  const { dropdownContainer, dropdownItem } = useStyles();

  const menuRef = useRef<HTMLDivElement>(null);

  // closing menu on window click
  useLayoutEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!menuRef.current?.contains(event.target as any)) {
        setIsUserDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <div className={dropdownContainer} ref={menuRef}>
      <div
        role="button"
        tabIndex={0}
        className={dropdownItem}
        onClick={() => logout({ returnTo: window.location.origin })}
        onKeyDown={() => logout({ returnTo: window.location.origin })}
      >
        <ExitToAppIcon sx={{ color: `${colorPalette.darkBlue}` }} />
        <p>Log Out</p>
      </div>
    </div>
  );
};

export default UserDropdownMenu;

import {
  useEffect, useState, useCallback,
} from 'react';
import ReactMapGL, {
  Marker, Layer, Source, NavigationControl,
} from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { ADDRESSCLOUD_TILE_ENV, OS_API_KEY } from 'env';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { colorPalette } from 'utils/variables';
import {
  CircularProgress, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import useTransformRequest from './useTransformRequest';
import jbaLayers from './jba/layers';
import jbaOverlays from './jba/overlays';
import useStyles from './Map.style';
import { IMap } from './types';

const mapStyle = `https://api.os.uk/maps/vector/v1/vts/resources/styles?key=${OS_API_KEY}`;

const isJbaLayerVisible = (jbaLayerId: string, visibleOverlays: Array<string>) => {
  const visibleJbaLayers: Array<string> = [];
  visibleOverlays.forEach((visibleOverlay) => {
    // @ts-ignore
    const jbaOverlay = jbaOverlays[visibleOverlay];
    if (jbaOverlay) {
      visibleJbaLayers.push(...jbaOverlay.layers);
    }
  });
  return visibleJbaLayers.includes(jbaLayerId);
};

const Map = ({
  matchCoordinates, toid, mapRef, isPdfGenerating,
}: IMap) => {
  const {
    mapContainer, satelliteImageryToggle, overlaysControl, overlaysControlContainer,
  } = useStyles();
  const [isSatelliteImageryVisible, setIsSatelliteImageryVisible] = useState<boolean>(false);

  const [visibleOverlays, setVisibleOverlays] = useState<Array<string>>([]);

  const [viewState, setViewState] = useState<{
    longitude: number, latitude: number, zoom: number
  }>({
    longitude: -0.1,
    latitude: 51.5,
    zoom: 10,
  });

  const handleOverlayChange = (event: any) => {
    setVisibleOverlays(event.target.value);
  };

  useEffect(() => {
    if (!matchCoordinates) return;
    setViewState((oldViewState) => ({
      ...oldViewState,
      longitude: matchCoordinates.lon,
      latitude: matchCoordinates.lat,
      zoom: 17,
    }));
  }, [matchCoordinates]);

  const onMove = useCallback(({ viewState: { zoom: newZoom } }: any) => {
    setViewState({ ...viewState, zoom: newZoom });
  }, [viewState]);

  // There is a bug in react-map-gl which means that restricting map viewstate outside of maplibre
  // doesn't restrict maplibre's internal viewstate.  This is a work around, see here:
  // https://github.com/visgl/react-map-gl/issues/1811
  const onMoveEnd = (e: any) => {
    if (e.ignore) return;
    e.target.setCenter([viewState.longitude, viewState.latitude], { ignore: true });
    e.target.setZoom(viewState.zoom, { ignore: true });
  };

  const transformRequestFunction = useTransformRequest();

  return (
    <section className={mapContainer}>
      <Paper className={satelliteImageryToggle}>
        <FormControlLabel
          control={<Switch checked={isSatelliteImageryVisible} onChange={() => setIsSatelliteImageryVisible(!isSatelliteImageryVisible)} value="satellite" />}
          label="Display Satellite Imagery"
        />
      </Paper>
      <Paper className={overlaysControlContainer} elevation={4}>
        <FormControl className={overlaysControl}>
          <Select
            multiple
            value={visibleOverlays}
            onChange={(e) => handleOverlayChange(e)}
            displayEmpty
            renderValue={(selected) => {
              const len = selected.length;
              return `${len === 0 ? 'No' : len} overlay${len === 1 ? '' : 's'} selected`;
            }}
            size="small"
          >
            <MenuItem
              key="adfrst"
              value="adfrst"
              disabled={viewState.zoom < 14}
            >
              <Checkbox checked={visibleOverlays.includes('adfrst')} />
              <ListItemText primary="Address Points" />
            </MenuItem>
            <MenuItem
              key="insidx"
              value="insidx"
              disabled={viewState.zoom < 14}
            >
              <Checkbox checked={visibleOverlays.includes('insidx')} />
              <ListItemText primary="Sites" />
            </MenuItem>
            <MenuItem
              key="geopln"
              value="geopln"
              disabled={viewState.zoom < 14}
            >
              <Checkbox checked={visibleOverlays.includes('geopln')} />
              <ListItemText primary="Postcode Boundaries" />
            </MenuItem>
            {
              Object.entries(jbaOverlays).map(([overlayId, overlay]) => (
                <MenuItem
                  key={overlayId}
                  value={overlayId}
                  disabled={viewState.zoom < overlay.minzoom}
                >
                  <Checkbox checked={visibleOverlays.includes(overlayId)} />
                  <ListItemText primary={overlay.name} />
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Paper>
      <ReactMapGL
        ref={mapRef}
        style={{ width: '100%', height: '100%' }}
        minZoom={6}
        maxZoom={18}
        cursor="auto"
        maxBounds={[
          [-10.76418, 49.528423],
          [1.9134116, 61.331151],
        ]}
        mapLib={maplibregl}
        mapStyle={mapStyle}
        transformRequest={transformRequestFunction}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...viewState}
        onMove={onMove}
        onMoveEnd={onMoveEnd}
        dragRotate={false}
        dragPan={false}
        keyboard={false}
        touchPitch={false}
        touchZoomRotate={false}
      >
        <Marker
          longitude={matchCoordinates?.lon}
          latitude={matchCoordinates?.lat}
          anchor="center"
          color={colorPalette.darkBlue}
          scale={0.6}
        />
        <NavigationControl position="top-left" showCompass={false} />
        <Source
          id="bing-imagery"
          type="raster"
          tiles={[
            'https://ecn.t0.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
            'https://ecn.t1.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
            'https://ecn.t2.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
            'https://ecn.t3.tiles.virtualearth.net/tiles/h{quadkey}.jpeg?g=587&mkt=en-gb&n=z',
          ]}
          tileSize={256}
          minzoom={6}
          attribution={'<a href="https://www.microsoft.com/en-us/maps/product/terms" target="_blank">&copy; Microsoft 2022</a>'}
        />
        <Layer id="bing-imagery" type="raster" source="bing-imagery" layout={{ visibility: isSatelliteImageryVisible ? 'visible' : 'none' }} />
        {
          toid ? (
            <Layer
              id="addresscloud-os-toid-highlight"
              type="fill"
              source="esri"
              source-layer="TopographicArea_2"
              paint={{
                'fill-color': colorPalette.yellow,
                'fill-opacity': 0.4,
                'fill-outline-color': '#000000',
              }}
              filter={['==', 'TOID', toid]}
            />
          ) : null
        }
        <Source id="addresscloud.adfrst" type="vector" url={`https://${ADDRESSCLOUD_TILE_ENV}.addresscloud.com/tile/v1/tileset/adfrst.json`}>
          <Layer
            id="adfrst"
            type="circle"
            source-layer="adfrst-address"
            minzoom={14}
            layout={{ visibility: visibleOverlays.includes('adfrst') ? 'visible' : 'none' }}
            paint={{
              'circle-color': '#000',
              'circle-radius': 2,
            }}
          />
        </Source>
        <Source id="addresscloud.insidx" type="vector" url={`https://${ADDRESSCLOUD_TILE_ENV}.addresscloud.com/tile/v1/tileset/insidx.json`}>
          <Layer
            id="insidx"
            type="line"
            source-layer="insidx"
            minzoom={14}
            layout={{ visibility: visibleOverlays.includes('insidx') ? 'visible' : 'none' }}
            paint={{
              'line-color': isSatelliteImageryVisible ? 'rgba(189, 185, 181, 1)' : 'rgba(70, 70, 70, 1)',
              'line-width': 0.5,
            }}
          />
        </Source>
        <Source id="addresscloud.geopln" type="vector" url={`https://${ADDRESSCLOUD_TILE_ENV}.addresscloud.com/tile/v1/tileset/geopln.json`}>
          <Layer
            id="geopln"
            type="line"
            source-layer="postcode"
            minzoom={14}
            layout={{ visibility: visibleOverlays.includes('geopln') ? 'visible' : 'none' }}
            paint={{
              'line-color': isSatelliteImageryVisible ? 'rgba(189, 185, 181, 1)' : 'rgba(70, 70, 70, 1)',
              'line-width': 0.5,
            }}
          />
        </Source>
        <Source id="addresscloud.jbafgb" type="vector" url={`https://${ADDRESSCLOUD_TILE_ENV}.addresscloud.com/tile/v1/tileset/jbafgb.json`}>
          {
            jbaLayers.map((layer) => {
              const layout = { ...layer.layout, visibility: isJbaLayerVisible(layer.id, visibleOverlays) ? 'visible' : 'none' };
              // @ts-ignore
              return (
                <Layer
                  key={layer.id}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...layer}
                  // @ts-ignore
                  layout={layout}
                />
              );
            })
          }
        </Source>
      </ReactMapGL>

      <Dialog open={isPdfGenerating}>
        <DialogTitle id="alert-dialog-title">Generating PDF</DialogTitle>
        <DialogContent>
          <div
            style={{ padding: 20, display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress sx={{ color: colorPalette.darkBlue }} />
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default Map;

/* eslint-disable max-len */
import { User } from '@auth0/auth0-react';
import { IPropertyInfo } from 'components/inputsArea/types';
import { jsPDF } from 'jspdf';
import { MapRef } from 'react-map-gl';
import { IBibaApiResult, IMatchResult } from 'types';
import { currency, selectTextColor } from './helpers';
import { logo, mapMarker } from './pdfImages';
import { colorPalette } from './variables';

const date = new Date();
const year = date.getFullYear();

const logoAspectRatio = 950 / 400;
const logoHeight = 9;

const markerAspectRatio = 16 / 25;
const markerHeight = 6;

// TO DO: to check author and keywords //
const copyright = `Copyright © OpenMapTiles © OpenStreetMap Contributors © MapTiler © Microsoft ${year} (Satellite Imagery)`;
const earthCircumference = 40075016.69;
const tileSize = 512;
const dotsPerInch = 300;

const layout = {
  width: 210,
  height: 297,
  margin: 15,
  mapHeight: 100,
};

// TO DO: to check author and keywords //
const pdfMetadata = {
  title: 'Property Assessment Report',
  subject: 'Property Assessment Report',
  author: '(c) Addresscloud',
  keywords: 'Addresscloud',
};

// ======================================================================================

// Computed properties
const logoWidth = logoAspectRatio * logoHeight;
const dotsPerMillimetre = Math.round(dotsPerInch / 25.4);

const x0 = layout.margin;
const xAddressEnd = x0 + 50;
const xCoordsStart = xAddressEnd + 5;
// const x1 = x0 + 5; // Change for inner margins in lists
// const x2 = x1 + 5;
// const x3 = x2 + 5;
const x5 = layout.width / 2 - 5;
// const x4 = x5 - 5;
const x6 = x5 + 10;
// const x7 = x6 + 5;
// const x8 = x7 + 5;
// const x9 = x8 + 5;
// const x10 = x9 + 5;
const x12 = layout.width - layout.margin;
const xLogoStart = x12 - logoWidth;
const x11 = x12 - 5;

const y0 = layout.margin;
const y1 = y0 + logoHeight + 5;
const y2 = y1 + 6;
const y3 = y2 + 10;
const y4 = y3 + layout.mapHeight;
const yScaleEnd = y4 - 5;
const y5 = y4 + 15;
const y6 = y5 + 7;
const y7 = y6 + 10;
const y10 = layout.height - layout.margin;
const y9 = y10 - 10;
const y8 = y9 - 5;

const [mapImageWidth, mapImageHeight] = [
  Math.ceil((x12 - x0) * dotsPerMillimetre),
  Math.ceil(layout.mapHeight * dotsPerMillimetre),
];

const toRadians = (angle: number) => angle * (Math.PI / 180);

const getScale = (zoom: number, lat: number, dotsPerMetre: number) => (earthCircumference * Math.cos(toRadians(lat)) * dotsPerMetre)
  / (2 ** zoom * tileSize);

const getMapImage = (
  map: MapRef,
  width: number,
  height: number,
  coordinates: [number, number],
) => {
  const bounds = map.getBounds();

  const container = map?.getContainer();
  const containerStyleWidth = container.style.width;
  const containerStyleHeight = container.style.height;
  container.style.visibility = 'hidden';

  const actualPixelRatio = window.devicePixelRatio;

  const targetPixelRatio = dotsPerInch / 96;
  Object.defineProperty(window, 'devicePixelRatio', {
    get() {
      return targetPixelRatio;
    },
  });
  const [targetWidth, targetHeight] = [width, height].map((side) => Math.ceil(side / targetPixelRatio));
  container.style.width = `${targetWidth}px`;
  container.style.height = `${targetHeight}px`;
  map.resize();
  map.fitBounds(bounds);

  return new Promise<[string, number, number[] | null]>((resolve) => {
    map.once('idle', () => {
      const printZoom = map.getZoom();
      const image = map.getCanvas().toDataURL('image/png');
      const markerPoint = coordinates[0] && coordinates[1] ? map.project(coordinates) : null;

      let markerRelative = markerPoint ? [markerPoint.x / targetWidth, markerPoint.y / targetHeight] : null;
      if (markerRelative && markerRelative.some((ratio) => ratio < 0 || ratio > 1)) markerRelative = null;

      Object.defineProperty(window, 'devicePixelRatio', {
        get() {
          return actualPixelRatio;
        },
      });
      [container.style.width, container.style.height] = [
        containerStyleWidth,
        containerStyleHeight,
      ];
      map.resize();
      map.fitBounds(bounds);

      resolve([image, printZoom, markerRelative]);
      container.style.visibility = 'visible';
    });
  });
};

const generatePdf = async (
  map: MapRef | null,
  matchResult: IMatchResult | null,
  bibaApiResult: IBibaApiResult | null,
  user: User | undefined,
  propertyInfo: IPropertyInfo | undefined,
) => {
  if (!map) return;
  if (matchResult) {
    const longitude = matchResult.geometry.coordinates.lon;
    const latitude = matchResult.geometry.coordinates.lat;
    // @ts-ignore
    const [image, printZoom, markerRelative] = await getMapImage(
      map,
      mapImageWidth,
      mapImageHeight,
      [longitude, latitude],
    );
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
    });

    // Map and marker
    pdf.addImage(image, 'png', x0, y3, x12 - x0, layout.mapHeight);
    // eslint-disable-next-line no-mixed-operators
    if (markerRelative) {
      pdf.addImage(
        mapMarker,
        'png',
        x0 + markerRelative[0] * (x12 - x0) - (markerHeight * markerAspectRatio) / 2,
        y3 + markerRelative[1] * layout.mapHeight - markerHeight,
        markerHeight * markerAspectRatio,
        markerHeight,
      );
    }

    //  Address information
    if (matchResult?.description) {
      pdf.setTextColor(colorPalette.grey);
      pdf.setFontSize(11);
      pdf.setFont('Helvetica', 'normal');
      pdf.text(matchResult?.description, x0, y0, {
        lineHeightFactor: 1.5,
        maxWidth: xAddressEnd - x0,
        baseline: 'top',
      });
      pdf.text(
        [
          `UPRN: ${matchResult?.properties.uprn}`,
          `Longitude: ${longitude.toPrecision(4)}`,
          `Latitude: ${latitude.toPrecision(4)}`,
        ],
        xCoordsStart,
        y0,
        { lineHeightFactor: 1.5, baseline: 'top' },
      );
    }
    pdf.addImage(logo, 'png', xLogoStart, y0, logoWidth, logoHeight);

    // Date and Email
    const email = user?.email || '';
    pdf.setFontSize(11);
    pdf.text([`${new Date(Date.now()).toLocaleDateString()}`, email], x12, y1, {
      lineHeightFactor: 1.5,
      align: 'right',
      baseline: 'top',
    });

    // Copyright
    pdf.setFontSize(9);
    pdf.text(copyright, x0, y10, { baseline: 'bottom' });

    // Scalebar
    pdf.setFontSize(9);
    const { lat } = map.getCenter();
    const dotsPerMetre = (window.devicePixelRatio * 96) / 0.0254;
    const scale = Math.round(getScale(printZoom, lat, dotsPerMetre));
    const scaleKm = Number.parseFloat(((0.05 * scale) / 1000).toPrecision(1)); // around 5cm scale bar paper length into map km
    const [barLength, barHeight] = [((scaleKm * 1000) / scale) * 1000, 2]; // scale bar paper mm
    pdf.lines(
      [
        [0, barHeight],
        [barLength / 2, 0],
        [0, -barHeight],
        [barLength / 2, 0],
        [0, barHeight],
      ],
      x12 - barLength - 20,
      yScaleEnd - barHeight,
    );
    pdf.text(`${scaleKm} km`, x11, yScaleEnd, { align: 'right' });

    let colBreakIdx: number | null = null;

    if (bibaApiResult) {
      pdf.setFont('Helvetica', 'normal');
      // classifications
      const classifications = [
        { label: 'BIBA Class', value: bibaApiResult.biba_class },
        {
          label: 'Building BIBA Class',
          value: bibaApiResult.building_biba_class ?? 'Not Available',
        },
      ];

      pdf.setTextColor(colorPalette.grey);
      pdf.setFontSize(11);
      pdf.setFont('Helvetica', 'normal');
      pdf.text(
        [
          `${classifications[0].label}: ${classifications[0].value}, ${classifications[1].label}: ${classifications[1].value}`,
        ],
        x0,
        y2,
        {
          align: 'left',
          baseline: 'top',
        },
      );

      // Sums Insured
      const sumsInsured = [
        {
          label: 'Buildings TSI',
          value: Number(propertyInfo?.buildingsTsi),
          margin: x0,
        },
        {
          label: 'Contents TSI',
          value: Number(propertyInfo?.contentsTsi),
          margin: 70,
        },
        {
          label: 'Business Interruption TSI',
          value: Number(propertyInfo?.businessInterruptionTsi),
          margin: 125,
        },
      ];

      // EGL Values
      const eglValues = [
        {
          label: 'Buildings EGL',
          value: bibaApiResult?.egl_buildings,
          margin: x0,
        },
        {
          label: 'Contents EGL',
          value: bibaApiResult?.egl_contents,
          margin: 70,
        },
        {
          label: 'Business Interruption EGL',
          value: bibaApiResult?.egl_bi,
          margin: 125,
        },
      ];

      const createTSIandEGL = (values: { label: string, value: number | bigint | undefined, margin: number }[], height: number) => {
        values.forEach((value) => {
          pdf.setTextColor(colorPalette.grey);
          pdf.setFontSize(11);
          pdf.setFont('Helvetica', 'normal');
          const valueText = value.value !== undefined ? currency.format(value.value) : 'No Data';
          pdf.text(
            `${value.label}: ${valueText}`,
            value.margin,
            height,
          );
        });
      };

      createTSIandEGL(sumsInsured, y5);
      createTSIandEGL(eglValues, y6);

      // Additional details list
      const bibaApiResultsList = [
        { label: 'Coastal Return Period', value: bibaApiResult.coastal_rp },
        { label: 'River Return Period', value: bibaApiResult.river_rp },
        { label: 'Surface Return Period', value: bibaApiResult.surface_rp },
        { label: 'Flood Risk', value: bibaApiResult.flood_risk },
        { label: 'EA Alert Area', value: bibaApiResult.ea_alert_area },
        { label: 'EA Warning Area', value: bibaApiResult.ea_warning_area },
        { label: 'EA Historic Flood Count', value: bibaApiResult.hist_event_count },
        { label: 'Floodscore Address Defended', value: bibaApiResult.floodscore_def_add },
        { label: 'Floodscore Address Undefended', value: bibaApiResult.floodscore_ud_add },
        { label: 'Floodscore Building Defended', value: bibaApiResult.floodscore_def_bld },
        { label: 'Floodscore Building Undefended', value: bibaApiResult.floodscore_ud_bld },
        { label: 'Floodscore Postcode Defended', value: bibaApiResult.floodscore_def_pco },
        { label: 'Floodscore Postcode Undefended', value: bibaApiResult.floodscore_ud_pco },
        { label: 'Surface Depth 1m', value: bibaApiResult.surface_depth_1m },
        { label: 'Groundwater Chalk', value: bibaApiResult.groundwaterchalk },
        { label: 'Listed Grade', value: bibaApiResult.listed_grade },
        { label: 'Footprint', value: bibaApiResult.footprint },
        { label: 'Mixed Used', value: bibaApiResult.mixed_use },
        { label: 'Use', value: bibaApiResult.use },
        { label: 'Multi Occupancy', value: bibaApiResult.multi_occ },
        { label: 'Site Building Count', value: bibaApiResult.site_building_count },
      ];

      bibaApiResultsList.forEach((element, idx) => {
        let left;
        let baseline;
        if (!colBreakIdx) {
          left = x0;
          baseline = idx * 7;
          if (baseline >= y8 - y7 - 9) colBreakIdx = idx;
        } else {
          left = x6;
          const rightIdx = idx - colBreakIdx - 1;
          baseline = rightIdx * 7;
          if (baseline >= y8 - y7) return;
        }

        if (
          element.label === 'Floodscore Address Defended'
          || element.label === 'Floodscore Address Undefended'
          || element.label === 'Floodscore Postcode Defended'
          || element.label === 'Floodscore Postcode Undefended'
          || element.label === 'Floodscore Building Defended'
          || element.label === 'Floodscore Building Undefended'
        ) {
          pdf.setFontSize(9);
          pdf.setTextColor(selectTextColor((Number(element.value))));
          pdf.text(`${element.label}:  ${element.value === undefined ? 'N/A' : element.value}`, left, y7 + baseline);
        } else if (element.label === 'Footprint') {
          pdf.setFontSize(9);
          pdf.setTextColor(colorPalette.grey);
          pdf.text(
            `${element.label}:  ${element.value === undefined ? 'N/A' : `${element.value}m2`}`,
            left,
            y7 + baseline,
          );
        } else if (
          element.label === 'River Return Period'
          || element.label === 'Surface Return Period'
          || element.label === 'Coastal Return Period'
        ) {
          pdf.setFontSize(9);
          pdf.setTextColor(colorPalette.grey);
          pdf.text(
            `${element.label}: ${element.value === undefined ? 'N/A' : `1 in ${element.value}`}`,
            left,
            y7 + baseline,
          );
        } else if (typeof element.value === 'boolean') {
          pdf.setFontSize(9);
          pdf.setTextColor(colorPalette.grey);
          pdf.text(`${element.label}:  ${element.value === true ? 'Yes' : 'No'}`, left, y7 + baseline);
        } else if (element.value === undefined) {
          pdf.setFontSize(9);
          pdf.setTextColor(colorPalette.grey);
          pdf.text(`${element.label}:  N/A`, left, y7 + baseline);
        } else {
          pdf.setFontSize(9);
          pdf.setTextColor(colorPalette.grey);
          pdf.text(`${element.label}:  ${element.value}`, left, y7 + baseline);
        }
      });
    }

    pdf.setProperties(pdfMetadata);
    pdf.save(`${pdfMetadata.title}.pdf`);
  }
};

export default generatePdf;

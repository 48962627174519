import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  navbarContainer: {
    height: '4rem',
    width: '100%',
    padding: '0 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imgContainer: {
    height: '3rem',
    width: '12rem',
    '& svg': {
      marginLeft: '-8%',
      width: '100%',
      height: '100%',
    },
  },
  iconsContainer: {
    marginRight: '0.5rem',
    marginLeft: 'auto',
  },
  userContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  loginButtonContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export default useStyles;

import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from '@auth0/auth0-react';
import axios, { AxiosResponse } from 'axios';
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { get as utilsApiGet, post as utilsApiPost } from 'utils/api';

interface IApiCredentials {
  apiKey: string;
  clientId: string;
}

interface IAuthContext {
  apiCredentials?: IApiCredentials;
  setApiCredentials: (apiCredentials: IApiCredentials) => void;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [apiCredentials, setApiCredentials] = useState<IApiCredentials>();

  const apiCredentialsState = useMemo(() => (
    { apiCredentials, setApiCredentials }
  ), [apiCredentials]);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <AuthContext.Provider
        value={apiCredentialsState}
      >
        {children}
      </AuthContext.Provider>
    </Auth0Provider>
  );
};

const defaultBaseURL = `${process.env.REACT_APP_ADDRESSCLOUD_API_DOMAIN}`;

const useAddresscloudApi = (baseURL: string = defaultBaseURL) => {
  const authContext = useContext(AuthContext);
  const apiCredentials = authContext?.apiCredentials;
  if (authContext === undefined || apiCredentials === undefined) {
    throw new Error(
      'useAddresscloudApi must be used with authenticated authProvider',
    );
  }

  const headers = useMemo(
    () => ({
      'x-client-id': apiCredentials.clientId,
      'x-api-key': apiCredentials.apiKey,
    }),
    [apiCredentials],
  );

  const get = useCallback(async (
    endpoint: string,
    params?: any,
  ): Promise<AxiosResponse> => utilsApiGet(endpoint, params, headers, baseURL), [headers, baseURL]);

  const post = useCallback(async (
    endpoint: string,
    data: any,
  ): Promise<AxiosResponse> => utilsApiPost(endpoint, data, headers, baseURL), [headers, baseURL]);

  return { get, post };
};

const useAddresscloudCredentials = (): IApiCredentials => {
  const authContext = useContext(AuthContext);
  const apiCredentials = authContext?.apiCredentials;
  if (authContext === undefined || apiCredentials === undefined) {
    throw new Error(
      'useAddresscloudCredentials must be used with authenticated authProvider',
    );
  }

  return { clientId: apiCredentials.clientId, apiKey: apiCredentials.apiKey };
};

interface IAsyncState {
  status: 'idle' | 'pending' | 'resolved' | 'rejected';
  error?: Error | null;
}

const AuthProtectedComponentWithoutAuth0Required: React.FC<{
  children: React.ReactNode;
  renderLoading: () => React.ReactElement;
  renderError: () => React.ReactElement;
}> = ({
  children,
  renderLoading,
  renderError,
}) => {
  const authContext = useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error('AuthProtectedComponent must be used with AuthProvider');
  }
  const { setApiCredentials } = authContext;

  const { getAccessTokenSilently, error: auth0Error } = useAuth0();
  const [{ status }, setState] = useReducer(
    (state: IAsyncState, newState: IAsyncState) => ({ ...state, ...newState }),
    { status: 'idle', error: null },
  );

  useEffect(() => {
    const getAddresscloudCredentials = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const axiosConfig = {
          method: 'get',
          url: `${process.env.REACT_APP_ADDRESSCLOUD_API_DOMAIN}/auth/v1/key/apps`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios(axiosConfig);

        const clientId = Object.keys(response.data)[0];
        const apiKey = response.data[clientId];
        setApiCredentials({ apiKey, clientId });
        setState({ status: 'resolved' });
      } catch (error) {
        setState({ status: 'rejected', error: error as Error });
      }
    };
    setState({ status: 'pending' });
    getAddresscloudCredentials();
  }, [getAccessTokenSilently, setApiCredentials]);

  if (status === 'rejected' || auth0Error) {
    return renderError();
  }

  if (status === 'pending' || status === 'idle') {
    return renderLoading();
  }

  return <>{children}</>;
};

const AuthProtectedComponent = withAuthenticationRequired(
  AuthProtectedComponentWithoutAuth0Required,
);
export {
  AuthProvider, useAddresscloudApi, useAddresscloudCredentials, AuthProtectedComponent,
};

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  footerContainer: {
    width: '100%',
    height: '3.5rem',
  },
  footerInsideContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 1.5rem',
  },
  addresscloudLogoContainer: {
    marginLeft: '2rem',
    width: '15%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& p': {
      fontSize: '0.8rem',
    },
    '& svg': {
      width: '60%',
      height: 'auto',
    },
  },
  miCommercialLogo: {
    marginLeft: '2rem',
    width: '15%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& p': {
      fontSize: '0.8rem',
    },
    '& img': {
      width: '60%',
      height: 'auto',
    },
  },
});

export default useStyles;

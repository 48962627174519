/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeEvent, FormEvent, useState,
} from 'react';
import {
  IconButton,
  Button,
  FormControl,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import Geocode from 'components/geocode/Geocode';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useBiba, { unnestOverridesInBibaResult } from 'hooks/use-biba';
import { colorPalette, iconsButtonStyle } from 'utils/variables';
import { IBibaApiResponse } from 'types';
import Claims from './Claims';
import { IInputsAreaProps } from './types';
import useStyles from './InputsArea.style';
import CustomInput from './CustomInput';

const InputsArea = ({
  handleMatch,
  matchResult,
  isFormSubmitted,
  setIsFormSubmitted,
  propertyInfo,
  setPropertyInfo,
  previousClaims,
  previousClaimsDispatch,
  setBibaApiResult,
}: IInputsAreaProps) => {
  const { lookupByUprn } = useBiba();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [intelError, setIntelError] = useState<string | null>(null);

  const {
    matchAddressResultHeader,
    matchAddressResultContainer,
    matchAddressResult,
    inputsAreaContainer,
    inputsContainer,
    inputs,
    concreteFloorsContainer,
    assessmentButtonContainer,
    intelErrorMessage,
  } = useStyles(openForm);

  // input handlers
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPropertyInfo({
      ...propertyInfo,
      [event.target.name]: event.target?.value,
    });
  };

  // handleForm submit
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIntelError(null);
    if (matchResult) {
      setIsLoading(true);
      const { uprn } = matchResult.properties;
      const {
        buildingsTsi, contentsTsi, businessInterruptionTsi, concreteFloor,
        minimumFloorLevel, hasBasement, bibaClass, buildingBibaClass,
      } = propertyInfo;
      const response = await lookupByUprn(
        uprn.toString(),
        minimumFloorLevel,
        hasBasement,
        Number(buildingsTsi),
        Number(contentsTsi),
        Number(businessInterruptionTsi),
        concreteFloor,
        previousClaims,
        bibaClass,
        buildingBibaClass,
      );

      if (response.data.intel_status === 'OK') {
        const bibaResponseResults: IBibaApiResponse = response.data.results;
        const bibaApiResult = unnestOverridesInBibaResult(
          bibaResponseResults.bibafl.bibafl.results[0],
        );
        setBibaApiResult(bibaApiResult);
        setIsFormSubmitted(true);
        const {
          floor_level,
          has_basement,
          biba_class,
          building_biba_class,
        } = bibaApiResult;
        setPropertyInfo({
          ...propertyInfo,
          minimumFloorLevel: floor_level || null,
          hasBasement: has_basement || null,
          bibaClass: biba_class || null,
          buildingBibaClass: building_biba_class || null,
        });
        setOpenForm(false);
      } else if (response.data.intel_status === 'ZERO_RESULTS') {
        setIntelError('Sorry, there is no BIBA data available for this property. Please try another property.');
      } else {
        setIntelError('Sorry, something went wrong. Please contact support@addresscloud.com if problem persists.');
      }
      setIsLoading(false);
    }
  };

  const handleCheck = () => {
    setPropertyInfo({ ...propertyInfo, concreteFloor: !propertyInfo.concreteFloor });
  };

  // expand/collapse form
  const toggleArrowClick = () => {
    setOpenForm(!openForm);
  };

  return (
    <>
      {isFormSubmitted !== false ? (
        <div className={matchAddressResultContainer}>
          <p style={{ width: '100%' }}>Policy Details</p>
          <h1
            className={matchAddressResultHeader}
            title={matchResult?.description}
          >
            {matchResult?.description}
          </h1>
          <IconButton
            onClick={toggleArrowClick}
            sx={iconsButtonStyle}
          >
            {!openForm ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </div>
      ) : null}
      {isFormSubmitted === false || openForm === true ? (
        <section className={inputsAreaContainer}>
          {!isFormSubmitted ? (
            <>
              <Geocode handleMatch={handleMatch} />
              {
                matchResult
                  ? (
                    <p className={matchAddressResult}>
                      {matchResult.description}
                    </p>
                  ) : null
              }
            </>
          ) : null}
          <form onSubmit={handleSubmit}>
            <div className={inputsContainer}>
              <FormControl sx={{ width: '48%' }}>
                <label htmlFor="buildingTsi">Buildings TSI</label>
                <div className={inputs}>
                  <CurrencyPoundIcon sx={{ color: colorPalette.yellow }} />
                  <CustomInput id="buildingsTsi" name="buildingsTsi" value={propertyInfo.buildingsTsi} handleChange={handleChange} />
                </div>
              </FormControl>
              <FormControl sx={{ width: '48%' }}>
                <label htmlFor="contentsTsi">Contents TSI</label>
                <div className={inputs}>
                  <CurrencyPoundIcon sx={{ color: colorPalette.yellow }} />
                  <CustomInput id="contentsTsi" name="contentsTsi" value={propertyInfo.contentsTsi} handleChange={handleChange} />
                </div>
              </FormControl>
            </div>
            <div className={inputsContainer}>
              <FormControl sx={{ width: '48%' }}>
                <label htmlFor="businessInterruptionTsi">Business Interruption TSI</label>
                <div className={inputs}>
                  <CurrencyPoundIcon sx={{ color: colorPalette.yellow }} />
                  <CustomInput id="businessInterruptionTsi" name="businessInterruptionTsi" value={propertyInfo.businessInterruptionTsi} handleChange={handleChange} />
                </div>
              </FormControl>
              <div className={concreteFloorsContainer}>
                <p>
                  <Checkbox
                    checked={propertyInfo.concreteFloor}
                    onChange={handleCheck}
                    sx={{ '&.Mui-checked': { color: colorPalette.darkBlue } }}
                  />
                  Does this property have concrete floors?
                </p>
              </div>
            </div>
            <Claims claims={previousClaims} claimsDispatch={previousClaimsDispatch} />
            <div className={assessmentButtonContainer}>
              <Button
                variant="outlined"
                type="submit"
                disabled={!matchResult || isLoading}
                sx={{
                  borderRadius: '4px',
                  borderColor: colorPalette.yellow,
                  color: colorPalette.yellow,
                  padding: '0.7rem',
                  minWidth: '10rem',
                  '&:hover': {
                    borderColor: colorPalette.darkBlue,
                    backgroundColor: colorPalette.secondaryDarkBlue,
                    color: colorPalette.darkBlue,
                  },
                }}
                onSubmit={handleSubmit}
              >
                {!isLoading ? 'Do Assessment' : <CircularProgress sx={{ color: colorPalette.darkBlue }} size={20} />}
              </Button>
            </div>
            {intelError
              ? (
                <p className={intelErrorMessage}>
                  {intelError}
                </p>
              )
              : null}
          </form>
        </section>
      ) : null}
    </>
  );
};

export default InputsArea;

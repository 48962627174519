import useStyles from 'App.style';
import { colorPalette } from 'utils/variables';

const AuthError: React.FC = () => {
  const { redirectsPageStyle } = useStyles();

  return (
    <main
      className={redirectsPageStyle}
    >
      <h1 style={{ marginBottom: '3rem', color: colorPalette.darkBlue }}>Sorry, we could not authenticate you.</h1>
      <p>
        Please contact
        <a href="mailto: support@addresscloud.com"> Addresscloud support</a>
        .
      </p>
    </main>
  );
};

export default AuthError;

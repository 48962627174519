import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  mapContainer: {
    width: '65%',
    height: '20rem',
    position: 'relative',
  },
  satelliteImageryToggle: {
    position: 'absolute',
    bottom: '0.7rem',
    right: '0.7rem',
    padding: '0.5rem',
    zIndex: 500,
  },
  overlaysControl: {
    padding: '0.5rem',
  },
  overlaysControlContainer: {
    position: 'absolute',
    top: '0.7rem',
    right: '0.7rem',
    zIndex: 500,
  },
});

export default useStyles;

import { CircularProgress } from '@mui/material';
import useStyles from 'App.style';
import { colorPalette } from 'utils/variables';

const Loading: React.FC = () => {
  const { redirectsPageStyle } = useStyles();
  return (
    <main className={redirectsPageStyle}>
      <CircularProgress sx={{ color: colorPalette.darkBlue }} />
    </main>
  );
};

export default Loading;

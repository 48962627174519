import React from 'react';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { CustomProps, ICustomInputProps } from './types';

const NumericFormatCustom = React.forwardRef((props: CustomProps, ref) => {
  const { onChange, name, ...other } = props;

  return (
    <NumericFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
    />
  );
});

const CustomInput = ({
  id, name, value, handleChange,
}: ICustomInputProps) => (
  <TextField
    id={id}
    value={value}
    onChange={handleChange}
    name={name}
    InputProps={{
      inputComponent: NumericFormatCustom as any,
    }}
    sx={{
      width: '93%',
      '& input': {
        border: 'none',
        outline: 'none',
        padding: '0.4rem',
        fontSize: '1rem',
      },
    }}
    variant="standard"
  />
);

export default CustomInput;

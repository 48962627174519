import { createUseStyles } from 'react-jss';
import { boxShadow, colorPalette } from 'utils/variables';

const useStyles = createUseStyles({
  resultsContainer: {
    height: '45%',
    width: '65%',
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  EGLResultsContainer: {
    background: 'white',
    padding: '1rem',
    borderRadius: '4px',
    boxShadow,
    width: '49.5%',
    '& div:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  EGLResultsStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& h2': {
      width: '100%',
    },
  },
  warningsContainer: {
    padding: '0.5rem',
    backgroundColor: colorPalette.yellow,
    borderRadius: '4px',
    '& h2': {
      width: '100%',
    },
  },
  referralsContainer: {
    padding: '0.5rem',
    backgroundColor: colorPalette.redScore,
    borderRadius: '4px',
    lineHeight: '1.35rem',
    '& h2': {
      width: '100%',
    },
  },
  preFilledResults: {
    background: 'white',
    padding: '1rem',
    borderRadius: '4px',
    boxShadow,
    width: '49.5%',
  },
  preFilledResultsStyle: {
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    '& h2': {
      width: '100%',
    },
  },
  floodMetrics: {
    marginTop: '1rem',
    width: '100%',
    background: 'white',
    padding: '1rem',
    borderRadius: '4px',
    boxShadow,
  },
  additionalDetailsHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
  },
  additionalDetailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0.5rem 1rem',
    borderBottom: `1px solid ${colorPalette.secondaryDarkBlue}`,
    '&:not(:first-child):hover': {
      backgroundColor: colorPalette.secondaryDarkBlue,
      cursor: 'pointer',
    },
  },
});

export default useStyles;

/* eslint-disable react/prop-types */
import {
  FormControl, IconButton, Input,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import { colorPalette } from 'utils/variables';
import useStyles from './InputsArea.style';
import { IClaim } from './types';
import CustomInput from './CustomInput';

const Claims: React.FC<{
  claims: Array<IClaim>,
  claimsDispatch: any
}> = ({
  claims,
  claimsDispatch,
}) => {
  const { inputs, headers } = useStyles();

  const toggleChecked = () => {
    if (claims.length === 0) {
      claimsDispatch({ type: 'add' });
    } else {
      claimsDispatch({ type: 'removeAll' });
    }
  };

  // handle claims inputs
  const handleClaimValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    claimNumber: number,
  ) => {
    const { value } = event.target;
    if (Number.isNaN(Number(value))) return;
    claimsDispatch({ type: 'update', claim: { claimValue: Number(value) }, claimNumber });
  };

  const handleInputYearChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    claimNumber: number,
  ) => {
    const { value } = event.target;
    if (Number.isNaN(Number(value))) return;
    if (value.length <= 4) claimsDispatch({ type: 'update', claim: { year: Number(value) }, claimNumber });
  };

  // handle plus and minus buttons click
  const handlePlusButtonClick = () => {
    claimsDispatch({ type: 'add' });
  };

  const handleMinusButtonClick = () => {
    claimsDispatch({ type: 'remove' });
  };

  return (
    <>
      <h1 className={headers}>Claims</h1>
      <p>
        <Checkbox checked={claims.length > 0} onChange={toggleChecked} sx={{ '&.Mui-checked': { color: colorPalette.darkBlue } }} />
        Does this property have any previous claim?
      </p>

      {claims.length > 0
        ? claims.map((claim: IClaim, index: number) => (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignContent: 'center',
              marginTop: '1rem',
            }}
              // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <FormControl sx={{ width: claims.length - 1 === index ? '45%' : '48%' }}>
              <label htmlFor="claimYear">Claim Year</label>
              <div className={inputs}>
                <CalendarMonthIcon sx={{ color: colorPalette.yellow }} />
                <Input
                  id="claimYear"
                  sx={{
                    width: '93%',
                    ':before': { borderBottomColor: colorPalette.darkBlue },
                    ':after': { borderBottomColor: colorPalette.darkBlue },
                  }}
                  value={claim.year ?? ''}
                  onChange={(event) => handleInputYearChange(event, index)}
                />
              </div>
            </FormControl>
            <FormControl sx={{ width: claims.length - 1 === index ? '45%' : '48%' }}>
              <label htmlFor="claimValue">Value</label>
              <div className={inputs}>
                <CurrencyPoundIcon sx={{ color: colorPalette.yellow }} />
                <CustomInput id="claimValue" name="claimValue" value={claim.claimValue ?? ''} handleChange={(event) => handleClaimValueChange(event, index)} />
              </div>
            </FormControl>
            {claims.length - 1 === index
              ? (
                <div>
                  <IconButton onClick={handlePlusButtonClick}>
                    <AddIcon />
                  </IconButton>
                  <IconButton onClick={handleMinusButtonClick}>
                    <RemoveIcon />
                  </IconButton>
                </div>
              ) : null}
          </div>
        )) : null}
    </>
  );
};

export default Claims;

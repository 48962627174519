/* eslint-disable @typescript-eslint/naming-convention */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { selectColors } from 'utils/helpers';
import { JbaFloodTableRowHeaders } from 'utils/variables';
import StyledTableCell from './StyledTableCell';
import { IJbaFloodTableProps } from './types';

const JbaFloodTable = ({ results }: IJbaFloodTableProps) => {
  const {
    floodscore_ud_add, floodscore_def_add, floodscore_ud_bld, floodscore_def_bld,
    floodscore_def_pco, floodscore_ud_pco,
  } = results;
  return (
    <TableContainer sx={{ margin: '1rem 0' }}>
      <Table>
        <TableHead>
          <TableRow>
            {JbaFloodTableRowHeaders.map((header) => (
              <StyledTableCell cellContent={header} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell cellContent="Address" />
            <StyledTableCell
              cellContent={floodscore_def_add}
              style={{
                backgroundColor: selectColors(floodscore_def_add).background,
                color: selectColors(floodscore_def_add).text,
              }}
            />
            <StyledTableCell
              cellContent={floodscore_ud_add}
              style={{
                backgroundColor: selectColors(floodscore_ud_add).background,
                color: selectColors(floodscore_ud_add).text,
              }}
            />
          </TableRow>
          <TableRow>
            <StyledTableCell cellContent="Building" />
            <StyledTableCell
              cellContent={floodscore_def_bld}
              style={{
                backgroundColor: selectColors(floodscore_def_bld).background,
                color: selectColors(floodscore_def_bld).text,
              }}
            />
            <StyledTableCell
              cellContent={floodscore_ud_bld}
              style={{
                backgroundColor: selectColors(floodscore_ud_bld).background,
                color: selectColors(floodscore_ud_bld).text,
              }}
            />
          </TableRow>
          <TableRow>
            <StyledTableCell cellContent="Postcode" />
            <StyledTableCell
              cellContent={floodscore_def_pco}
              style={{
                backgroundColor: selectColors(floodscore_def_pco).background,
                color: selectColors(floodscore_def_pco).text,
              }}
            />
            <StyledTableCell
              cellContent={floodscore_ud_pco}
              style={{
                backgroundColor: selectColors(floodscore_ud_pco).background,
                color: selectColors(floodscore_ud_pco).text,
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default JbaFloodTable;

import { createUseStyles } from 'react-jss';
import BIBABanner from 'assets/BIBABanner.png';

const useStyles = createUseStyles({
  app: {
    minHeight: '100vh',
    backgroundImage: `url(${BIBABanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    webkitBackgroundSize: 'cover',
    mozBackgroundSize: 'cover',
    backgroundSize: 'cover',
  },
  mainContainer: {
    width: '100%',
    minHeight: 'calc(100vh - 7.5rem)',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  redirectsPageStyle: {
    height: '100vh',
    width: '100wh',
    backgroundImage: `url(${BIBABanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    webkitBackgroundSize: 'cover',
    mozBackgroundSize: 'cover',
    backgroundSize: 'cover',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default useStyles;

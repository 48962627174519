/* eslint-disable @typescript-eslint/naming-convention */
import { IconButton } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { iconsButtonStyle } from 'utils/variables';
import JbaFloodTable from './JbaFloodTable';
import JbaReturnPeriod from './JBAReturnPeriod';
import useStyles from './Results.style';
import { IAdditionalDetailsProps } from './types';

const AdditionalDetails = ({ results }: IAdditionalDetailsProps) => {
  const [isAdditionalDetailsOpen, setIsAdditionalDetailsOpen] = useState(false);

  const {
    floodMetrics,
    additionalDetailsHeaderContainer,
    additionalDetailsItem,
  } = useStyles();

  const {
    use, mixed_use, multi_occ,
    site_building_count, listed_grade, footprint, groundwaterchalk, surface_depth_1m,
    flood_risk, ea_warning_area, ea_alert_area, hist_event_count,
  } = results;

  const toggleClick = () => {
    setIsAdditionalDetailsOpen(!isAdditionalDetailsOpen);
  };

  return (
    <div className={floodMetrics}>
      <div className={additionalDetailsHeaderContainer}>
        <h3>Additional Details</h3>
        <IconButton onClick={toggleClick} sx={iconsButtonStyle}>
          {!isAdditionalDetailsOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </div>
      {!isAdditionalDetailsOpen ? null : (
        <div>
          <div className={additionalDetailsItem} style={{ alignItems: 'flex-start' }}>
            <div style={{ width: '48.5%' }}>
              <p style={{ fontWeight: 400 }}>JBA Flood Scores</p>
              <JbaFloodTable results={results} />
            </div>
            <div style={{ width: '48.5%' }}>
              <p style={{ fontWeight: 400 }}>JBA Max Return period</p>
              <JbaReturnPeriod results={results} />
            </div>
          </div>
          <div className={additionalDetailsItem}>
            <p>EA Warning Area</p>
            <span>{ea_warning_area ? 'Yes' : 'No'}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>EA Alert Area</p>
            <span>{ea_alert_area ? 'Yes' : 'No'}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>EA Historic Flood Count</p>
            <span>{hist_event_count === undefined ? 'N/A' : hist_event_count}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Groundwater Chalk</p>
            <span>{groundwaterchalk ? 'Yes' : 'No'}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Surface Water Depth &gt; 1m</p>
            <span>{surface_depth_1m ? 'Yes' : 'No'}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Supplementary Flood Risk Indicator</p>
            <span>{flood_risk}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Use</p>
            <span>{use}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Mixed Use</p>
            <span>{mixed_use ? 'Yes' : 'No'}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Multi Occupancy</p>
            <span>{multi_occ}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Site Building Count</p>
            <span>{site_building_count}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Listed Grade</p>
            <span>{listed_grade === undefined ? 'N/A' : listed_grade}</span>
          </div>
          <div className={additionalDetailsItem}>
            <p>Footprint</p>
            <span>
              {footprint}
              &#13217;
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdditionalDetails;

import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as BIBALogo } from 'assets/BIBALogo.svg';
import { Button, Tooltip } from '@mui/material';
import UserAvatar from 'components/user/UserAvatar';
import UserDropdownMenu from 'components/user/UserDropdownMenu';
import { colorPalette, iconsButtonStyle } from 'utils/variables';
import useStyles from './Navbar.style';
import { INavbarProps } from './types';

const Navbar = ({
  handlePdf, refreshApp, isPdfGenerationDisabled,
}: INavbarProps) => {
  const {
    user, logout, isAuthenticated, loginWithRedirect,
  } = useAuth0();
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const {
    navbarContainer, imgContainer, iconsContainer,
    userContainer, loginButtonContainer,
  } = useStyles();

  // toggle user  dropdown menu
  const handleClick = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  const handleLogInClick = () => {
    loginWithRedirect();
  };

  const handleRefreshButtonClick = () => {
    refreshApp();
  };

  const handleCreatePdfButtonClick = async () => {
    await handlePdf();
  };

  return (
    <div className={navbarContainer}>
      <div
        className={imgContainer}
      >
        <BIBALogo />
      </div>
      <div className={iconsContainer}>
        <Tooltip title="Run New Assessment">
          <IconButton
            sx={iconsButtonStyle}
            onClick={handleRefreshButtonClick}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Create Results PDF">
          {/* wrapper span to fix MUI tootltip error when button is disabled */}
          <span style={{ cursor: 'pointer' }}>
            <IconButton
              disabled={isPdfGenerationDisabled}
              sx={iconsButtonStyle}
              onClick={handleCreatePdfButtonClick}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      {isAuthenticated ? (
        <div className={userContainer}>
          <UserAvatar handleClick={handleClick} user={user} />
          {isUserDropdownOpen && (
            <UserDropdownMenu
              logout={logout}
              setIsUserDropdownOpen={setIsUserDropdownOpen}
            />
          )}
        </div>
      ) : (
        <div className={loginButtonContainer}>
          <Button
            variant="outlined"
            sx={{
              background: colorPalette.primaryBlue,
              fontWeight: '300',
              padding: '0.5rem 1.5rem',
              '&:hover': {
                background: colorPalette.yellow,
              },
            }}
            onClick={handleLogInClick}
          >
            Log In
          </Button>
        </div>
      )}
    </div>
  );
};

export default Navbar;

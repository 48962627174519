import { createUseStyles } from 'react-jss';
import { colorPalette } from 'utils/variables';

const useStyles = createUseStyles({
  userAvatar: {
    width: '3rem',
    height: '3rem',
    '& img': {
      width: '100%',
      height: 'auto',
      borderRadius: '50%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  alternativePic: {
    width: '4rem',
    height: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colorPalette.yellow,
    borderRadius: '50%',
    cursor: 'pointer',
    '& p': {
      fontWeight: 400,
      color: 'white',
      fontSize: '2.5rem',
    },
  },
});

export default useStyles;

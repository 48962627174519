import { TransformRequestFunction } from 'react-map-gl';
import { RequestParameters } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useAddresscloudCredentials } from 'context/auth-context';

const getTilesetId = (url: string): string => {
  if (url.endsWith('.json')) {
    const jsonFileName = url.split('/').pop();
    if (!jsonFileName) throw new Error('Invalid tileset url');
    return jsonFileName.split('.')[0];
  }
  if (url.endsWith('.pbf')) {
    return url.split('/')[6];
  }
  throw new Error('Invalid tileset url');
};

const addresscloudTileRegex = /https:\/\/.*\.addresscloud\.com\/tile\//i;

const useTransformRequest = (): TransformRequestFunction => {
  const { clientId, apiKey } = useAddresscloudCredentials();

  return (url: string): RequestParameters => {
    if (url.startsWith('https://api.os.uk/')) {
      return {
        url: `${url}&srs=3857`,
      };
    }
    if (addresscloudTileRegex.test(url)) {
      const tilesetId = getTilesetId(url);
      return {
        url,
        headers: {
          'x-api-key': apiKey,
          'x-client-id': clientId,
          'x-tileset-id': tilesetId,
        },
      };
    }
    return { url };
  };
};

export default useTransformRequest;

import { useAddresscloudApi } from 'context/auth-context';

const useMatch = () => {
  const { get } = useAddresscloudApi();

  const geocode = async (query: string) => get('match/v1/address/geocode', { query, country: 'gb' });

  const lookupByAddresscloudId = async (addresscloudId: string) => get(`match/v1/address/lookup/byId/${addresscloudId}`);

  return { geocode, lookupByAddresscloudId };
};

export default useMatch;

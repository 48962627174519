import { currency } from 'utils/helpers';
import Referral from './Referral';
import useStyles from './Results.style';
import { IEGLResultsProps } from './types';

const EGLResults = ({ results, propertyInfo }: IEGLResultsProps) => {
  const { buildingsTsi, contentsTsi, businessInterruptionTsi } = propertyInfo;
  const {
    EGLResultsContainer, EGLResultsStyle,
  } = useStyles();

  const {
    egl_bi: eglBi,
    egl_buildings: eglBuildings,
    egl_contents: eglContents,
    referral,
  } = results;

  return (
    <div className={EGLResultsContainer}>
      <div className={EGLResultsStyle}>
        <h2>Buildings EGL</h2>
        <p>
          Sum Insured:
          {' '}
          {buildingsTsi === '' ? '£0' : currency.format(Number(buildingsTsi))}
        </p>
        <span>
          {
            eglBuildings !== undefined
              ? currency.format(eglBuildings)
              : 'No Data'
          }
        </span>
      </div>
      <div className={EGLResultsStyle}>
        <h2>
          Contents EGL
        </h2>
        <p>
          Sum Insured:
          {' '}
          {contentsTsi === '' ? '£0' : currency.format(Number(contentsTsi))}
        </p>
        <span>
          {
            eglContents !== undefined
              ? currency.format(eglContents)
              : 'No Data'
          }
        </span>
      </div>
      <div className={EGLResultsStyle}>
        <h2>Business Interruption EGL</h2>
        <p>
          Sum Insured:
          {' '}
          {businessInterruptionTsi === '' ? '£0' : currency.format(Number(businessInterruptionTsi))}
        </p>
        <span>
          {
            eglBi !== undefined
              ? currency.format(eglBi)
              : 'No Data'
          }
        </span>
      </div>
      <Referral referral={referral} />
    </div>
  );
};

export default EGLResults;

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  formStyle: {
    position: 'relative',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
  },
  addressesList: {
    position: 'absolute',
    background: 'white',
    maxHeight: '10rem',
    width: '56.5%',
    overflowY: 'scroll',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 10px',
    zIndex: 2,
  },
});

export default useStyles;

import {
  FC, FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import useMatch from 'hooks/use-match';
import { IMatchResult } from 'types';
import {
  Button, InputBase, ListItem, ListItemButton, ListItemText, List, CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { colorPalette } from 'utils/variables';
import useStyles from './Geocode.style';

interface IMultimatchResult {
  id: string;
  description: string;
}

const Geocode: FC<{
  handleMatch: (matchResult: IMatchResult) => void;
}> = ({ handleMatch }) => {
  const [addressQuery, setAddressQuery] = useState<string>('');
  const [multimatchList, setMultimatchList] = useState<Array<IMultimatchResult> | null>(null);
  const [isNoMatch, setIsNoMatch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { geocode, lookupByAddresscloudId } = useMatch();

  const menuRef = useRef<HTMLDivElement>(null);

  const {
    formStyle, inputContainer, addressesList,
  } = useStyles();

  const handleMultiMatch = useCallback(
    (multimatchResults: Array<IMultimatchResult>) => {
      const newMultimatchList = multimatchResults.map(
        (result: IMultimatchResult) => ({
          id: result.id,
          description: result.description,
        }),
      );
      setMultimatchList(newMultimatchList);
    },
    [],
  );

  const handleMultimatchSelect = async (addressId: string) => {
    setIsNoMatch(false);
    setMultimatchList(null);

    const response = await lookupByAddresscloudId(addressId);
    handleMatch(response.data.result);
  };

  const resetQuery = () => {
    setAddressQuery('');
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsNoMatch(false);
    setMultimatchList(null);
    setIsLoading(true);
    const response = await geocode(addressQuery);

    if (response.data.match_status === 'MATCH') {
      handleMatch(response.data.results[0]);
      resetQuery();
      setIsLoading(false);
      return;
    }

    if (response.data.match_status === 'NO_MATCH') {
      setIsNoMatch(true);
      resetQuery();
      setIsLoading(false);
      return;
    }

    if (response.data.match_status === 'MULTIMATCH') {
      handleMultiMatch(response.data.results);
      resetQuery();
      setIsLoading(false);
      return;
    }

    throw new Error('Unrecognised match_status');
  };

  const handleInputChange = (event: any): void => {
    setAddressQuery(event.target.value);
  };

  // close dropdown on click out
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      // @ts-ignore
      if (!menuRef?.current?.contains(event.target)) {
        setMultimatchList([]);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <>
      <form id="form" className={formStyle} onSubmit={handleSubmit}>
        <label htmlFor="form">Address</label>
        <div className={inputContainer}>
          <InputBase
            type="search"
            name="addressQuery"
            value={addressQuery}
            onChange={handleInputChange}
            placeholder="Enter an address or a postcode"
            startAdornment={<SearchIcon sx={{ color: colorPalette.yellow }} />}
            sx={{
              width: '90%',
              borderRadius: '4px',
              border: `1px solid ${colorPalette.yellow}`,
              padding: '0.5rem',
            }}
          />
          <Button
            variant="outlined"
            type="submit"
            sx={{
              width: '9%',
              borderRadius: '4px',
              borderColor: colorPalette.yellow,
              color: colorPalette.yellow,
              '&:hover': {
                borderColor: colorPalette.darkBlue,
                backgroundColor: colorPalette.secondaryDarkBlue,
                color: colorPalette.darkBlue,
              },
            }}
            disabled={isLoading}
          >
            {!isLoading ? 'Search' : <CircularProgress sx={{ color: colorPalette.darkBlue }} size={20} />}
          </Button>
        </div>
      </form>
      {multimatchList ? (
        <div className={addressesList} ref={menuRef}>
          <List sx={{ padding: 0 }} aria-label="matched addresses">
            {multimatchList.map((multimatchItem: IMultimatchResult) => (
              <ListItem sx={{ padding: 0 }} key={multimatchItem.id}>
                <ListItemButton
                  component="a"
                  onClick={() => handleMultimatchSelect(multimatchItem.id)}
                  sx={{ '&:hover': { background: colorPalette.secondaryYellow } }}
                >
                  <ListItemText primary={multimatchItem.description} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      ) : null}
      {isNoMatch ? <p>No Match</p> : null}
    </>
  );
};

export default Geocode;

export const defaultPropertyInfo = {
  buildingsTsi: '',
  contentsTsi: '',
  businessInterruptionTsi: '',
  concreteFloor: false,
  bibaClass: null,
  buildingBibaClass: null,
  minimumFloorLevel: null,
  hasBasement: null,
};

export const colorPalette = {
  primaryBlue: '#0064DC',
  darkBlue: '#0F143C',
  yellow: '#FFC327',
  secondaryYellow: '#FFF8E4',
  secondaryDarkBlue: '#E1E2E7',
  redScore: '#fca79a',
  greenScore: '#a3e6bb',
  yellowScore: '#f0f095',
  blackScore: '#5e5b5b',
  redScoreText: '#810020',
  greenScoreText: '#39B7A3',
  grey: '#657287',
};

export const boxShadow = 'rgba(0, 0, 0, 0.16) 0px 1px 4px';

export const iconsButtonStyle = {
  borderColor: colorPalette.yellow,
  color: colorPalette.yellow,
  '&:hover': {
    borderColor: colorPalette.darkBlue,
    backgroundColor: colorPalette.secondaryDarkBlue,
    color: colorPalette.darkBlue,
  },
};

export const JbaFloodTableRowHeaders = ['', 'Defended', 'Undefended'];
export const JbaReturnPeriodTableHeaders = ['River Max Return Period', 'Surface Water Max Return Period', 'Coastal Max Return Period'];

import { TableCell } from '@mui/material';
import { colorPalette } from 'utils/variables';
import { IStyledCellProps } from './types';

const StyledTableCell = ({ cellContent, style }: IStyledCellProps) => (
  <TableCell
    sx={{
      fontFamily: 'Poppins, sans-serif', textAlign: 'center', border: `1px solid ${colorPalette.secondaryDarkBlue}`, padding: '0.5rem', maxWidth: '4rem',
    }}
    style={style}
  >
    {cellContent}
  </TableCell>
);

export default StyledTableCell;

import { colorPalette } from './variables';

// eslint-disable-next-line import/prefer-default-export
export const extractInitial = (email: string | undefined) => email?.charAt(0).toUpperCase();

export const selectColors = (score: number) => {
  let colors = { background: 'white', text: colorPalette.darkBlue };
  if (score === undefined) {
    colors = { ...colors };
  } else if (score >= 0 && score < 7) {
    colors.background = colorPalette.greenScore;
    colors.text = colorPalette.darkBlue;
  } else if (score >= 7 && score < 12) {
    colors.background = colorPalette.yellowScore;
    colors.text = colorPalette.darkBlue;
  } else if (score >= 12 && score < 16) {
    colors.background = colorPalette.redScore;
    colors.text = colorPalette.darkBlue;
  } else {
    colors.background = colorPalette.blackScore;
    colors.text = 'white';
  }
  return colors;
};

export const selectTextColor = (value: number) => {
  let textColor;
  if (value >= 0 && value < 7) {
    textColor = colorPalette.greenScoreText;
  } else if (value >= 7 && value < 12) {
    textColor = colorPalette.yellow;
  } else if (value >= 12 && value < 16) {
    textColor = colorPalette.redScoreText;
  } else {
    textColor = colorPalette.darkBlue;
  }
  return textColor;
};

export const currency = Intl.NumberFormat('en-UK', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

import { User } from '@auth0/auth0-react';
import { extractInitial } from 'utils/helpers';
import useStyles from './UserAvatar.style';

type UserAvatarProps = {
  handleClick: () => void,
  user: User | undefined,
};

const UserAvatar = ({ handleClick, user }: UserAvatarProps) => {
  const { userAvatar, alternativePic } = useStyles();

  const userAlternativePic = user ? (
    <div role="button" tabIndex={0} className={alternativePic} onClick={handleClick} onKeyDown={handleClick}>
      <p>{extractInitial(user.email)}</p>
    </div>
  ) : null;

  return (
    <div role="button" tabIndex={0} className={userAvatar} onClick={handleClick} onKeyDown={handleClick}>
      {user?.picture ? (
        <img src={user.picture} alt="user avatar" />
      ) : (
        userAlternativePic
      )}
    </div>
  );
};

export default UserAvatar;
